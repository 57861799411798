<template>
  <v-card
    elevation="2"
    outlined
  >
    <v-card-title class="title text--primary text-left">
      Hjælp
    </v-card-title>
    <v-card-subtitle>
      Her finder du svar på de mest almindelige spørgsmål.
    </v-card-subtitle>
    <v-card-text class="text--primary text-left">
      <p>
        Har du brug for hjælp til brugen af TPA portalen og ikke kan finde svar på dit spørgsmål her, er du velkommen til at kontakte vores support.
      </p>

      <p>
        Ring på <a href="tel:+4544999998">4499 9998</a> eller skriv til <a href="mailto:support@tpa-solutions.dk">support@tpa-solutions.dk</a>.
      </p>

      <p>
        Vi har normal kontortid: mandag til torsdag kl. 8-17, fredag kl. 8-16.
      </p>

      <v-alert
        outlined
        type="warning"
        border="top"
        max-width="500"
        class="text-left"
      >
        Der er ikke oprettet nogen FAQ elementer.
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Help"
};
</script>